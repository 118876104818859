<template>
    <div class="say-box">
        <div class="say-box-center">
            <div class="say-box-s">
                <div>
                    <div 
                        class="say-box-user"
                        v-for="(item,index) in $store.state.chatArrForm"
                        :key="index"
                        @click="tabGril(item)"
                        :class="{activat: $store.state.chatForm.userId == item.receiveUser.userId}">
                        <img :src="$isApi.http + item.receiveUser.header" alt="">
                        <div>{{item.receiveUser.nickName}}</div>
                        <div @click.stop="closeUser(item,index)" class="iconfont icon-guanbi"></div>
                        <div v-if="item.noReadCount > 0" class="say-nosay">{{item.noReadCount}}</div>
                        <div class="zai-xian" v-if="item.receiveUser.isLine"></div>
                    </div>
                </div>
                <div>
                    <div class="say-top">
                        <div>
                            <img :src="$isApi.http + $store.state.chatForm.header" alt="">
                            <div class="iconfont icon-huiyuan"></div>
                        </div>
                        <div>
                            <div>{{$store.state.chatForm.nickName}}</div>
                            <div>{{$store.state.chatForm.city}}</div>
                        </div>
                        <div @click="colseSay" class="iconfont icon-guanbi"></div>
                    </div>
                    <div class="say-gift">
                        <div class="say-gift-left">
                            <div @click="goUrl($store.state.chatForm)">
                                <i style="background:#FF6396" class="iconfont icon-kehuquanyi"></i>
                                <span>{{$t('say.content')}}</span>
                            </div>
                            <div @click="goGift($store.state.chatForm)">
                                <i style="background:#C671EF" class="iconfont icon-gift-fill"></i>
                                <span>{{$t('say.setGift')}}</span>
                            </div>
                        </div>
                    </div>
                    <div 
                        v-loading="imgUpLoading"
                        class="say-chat" 
                        v-for="(item,index) in $store.state.chatArrForm" 
                        :key="index" 
                        v-if="item.receiveUser.userId == $store.state.chatForm.userId">
                        <JwChat 
                            :taleList="item.chatRecord"
                            :config="configChange"
                            :toolConfig="tool"
                            width="100%"
                            height="100%"
                            scrollType="scroll"
                            @enter="bindEnter(item)"
                            v-model="inputMsg">
                        </JwChat>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
export default {
    data(){
        return{
            imgUpLoading: false,
            que: require("../assets/images/que.jpg"),
            inputMsg: '',
            tool: {
                show: ["file"],
                showEmoji: true, // 是否显示表情图标
                callback: this.toolEvent
            },
            configChange: {
                historyConfig: {
                    show: true,
                    tip: "------ " + this.$i18n.t('login.more') + " ------",
                    callback: this.callChange
                }
            },
            sendForm: {},  //本人
            pageSize: 0
        }
    },
    created(){
        this.sendForm = JSON.parse(sessionStorage.getItem('sendForm'))  //我的信息
    },
    computed: {
        getContent(){
            return this.$store.state.getContent
        }
    },
    watch: {
        getContent(data){
            var ccc = this.$store.state.chatArrForm.find((v) => {
                return v.receiveUser.userId == data.socketSendId
            })
            if(ccc){
                if(ccc.receiveUser.userId == this.$store.state.chatForm.userId){
                    ccc.noReadCount = 0
                    this.$isPulic.crtime((e,c,b)=>{
                        var chatJson = {
                            date: c,
                            text: {text: data.content},
                            mine: false,
                            name: ccc.receiveUser.nickName,
                            img: this.$isApi.http + ccc.receiveUser.header
                        }
                        ccc.chatRecord.push(chatJson)
                    })
                }else{
                    ccc.noReadCount++
                    this.$isPulic.crtime((e,c,b)=>{
                        var chatJson = {
                            date: c,
                            text: {text: data.content},
                            mine: false,
                            name: ccc.receiveUser.nickName,
                            img: this.$isApi.http + ccc.receiveUser.header
                        }
                        ccc.chatRecord.push(chatJson)
                    })
                }
            }else{
                this.$isChat.chatList(this.sendForm,res=>{})
            }
        }
    },
    methods: {
        // 历史消息
        callChange(){
			this.pageSize++
			var json = {
				receiveId: this.$store.state.chatForm.userId,
				currentPage: this.pageSize
			}
			this.$isAxios.axiosGet(this.$isApi.showChatRecordsMore,json,res=>{
				if(res.status == '200'){
					if(res.data.result.length > 0){
						var ccc = this.$store.state.chatArrForm.find((v) => {
                            return v.receiveUser.userId == this.$store.state.chatForm.userId
                        })
                        ccc.chatRecord = [...res.data.result,...ccc.chatRecord]
					}else{
                        this.$message(this.$i18n.t('login.allMore'))
					}
				}
			})
        },
        // 送礼
        goGift(chatForm){
            if(this.sendForm.level != 0){
                this.$store.state.setGiftUserForm = chatForm
                this.$store.state.giftShow = true
            }else{
                this.$confirm(this.$i18n.t('login.shenVipState2'), this.$i18n.t('giftComponents.reminder'), {
                    confirmButtonText: this.$i18n.t('userLeft.success'),
                    cancelButtonText: this.$i18n.t('giftComponents.cancel'),
                    type: 'warning'
                }).then(() => {
                    this.$store.state.sayShow = false
                    this.$nextTick(()=>{
                        this.$store.state.upgradeShow = true
                    })
                })
            }
        },
        toolEvent (type, plyload) {
            this.imgUpLoading = true
            if(type == 'file'){
                let file = plyload[0]
                let param = new FormData() 
                param.append('imgFile', file)
                param.append('imgType', 'chatImg')
                axios.post(this.$isApi.uploadImg, param)
                .then( response => {
                    this.imgUpLoading = false
                    var ccc = this.$store.state.chatArrForm.find((v) => {
                        return v.receiveUser.userId == this.$store.state.chatForm.userId
                    })
                    this.$isPulic.crtime((e,c,b)=>{
                        if(response.data.path.IsPicture(".jpg") || response.data.path.IsPicture(".png") || response.data.path.IsPicture(".gif") || response.data.path.IsPicture(".jpeg")){
                            var chatJson = {
                                date: c,
                                text: {text: "<img data-src='" + this.$isApi.http + response.data.path + "' />"},
                                mine: true,
                                name: this.sendForm.nickName,
                                img: this.$isApi.http + this.sendForm.header
                            }
                            this.$nextTick(()=>{
                                var json = {
                                    receiveId: ccc.receiveUser.userId,
                                    content: "<img data-src='" + this.$isApi.http + response.data.path + "' />"
                                }
                                ccc.chatRecord.push(chatJson)
                                this.$store.commit('setSocke',JSON.stringify(json))
                            })
                        }else{
                            var chatJson = {
                                date: c,
                                text: {text: "<video data-src='" + this.$isApi.http + response.data.path + "' controls='controls' />"},
                                mine: true,
                                name: this.sendForm.nickName,
                                img: this.$isApi.http + this.sendForm.header
                            }
                            this.$nextTick(()=>{
                                var json = {
                                    receiveId: ccc.receiveUser.userId,
                                    content: "<video data-src='" + this.$isApi.http + response.data.path + "' controls='controls' />"
                                }
                                ccc.chatRecord.push(chatJson)
                                this.$store.commit('setSocke',JSON.stringify(json))
                            })
                        }
                    })
                })
            }
        },
        // 关闭聊天窗口
        colseSay(){
            this.pageSize = 0
            this.$store.state.sayShow = false
            this.$store.state.sayNumber = 0
            this.$isAxios.axiosGet(this.$isApi.closeChat,{},(res)=>{})
        },
        // 关闭指定用户
        closeUser(e,index){
            this.pageSize = 0
            this.$isAxios.axiosGet(this.$isApi.removeChat,{chatListId:e.chatListId},(res)=>{
                this.$store.state.chatArrForm = this.$store.state.chatArrForm.filter(item => item.chatListId !== e.chatListId)
                if(this.$store.state.chatArrForm.length == 0){
                    this.colseSay()
                    this.$store.state.chatForm = {}
                }else if(this.$store.state.chatArrForm.length == index){
                    this.$store.state.chatForm = this.$store.state.chatArrForm[index - 1].receiveUser
                }else if(this.$store.state.chatArrForm.length > index){
                    this.$store.state.chatForm = this.$store.state.chatArrForm[index].receiveUser
                }
            })
        },
        // 跳转用户资料
        goUrl(e){
            if(e.userId != '2c988fde790e55f801791232ef9b0117'){
                this.$store.state.sayShow = false
                sessionStorage.setItem('userId', e.userId)
                this.$isAxios.axiosPost(this.$isApi.addVisitor,{userId:e.userId,visitorId:this.sendForm.userId},(fang)=>{
                    this.$router.push({
                        path: "/page/userXQ",
                    })
                })
            }
        },
        // 切换聊天人员
        tabGril(item){
            if(this.sendForm.level != 0 || item.receiveUser.userId == '2c988fde790e55f801791232ef9b0117'){
                this.pageSize = 0
                this.$isAxios.axiosGet(this.$isApi.chattingInfo,{sendId:this.sendForm.userId,receiveId:item.receiveUser.userId},(res) => {
                    item.noReadCount = 0
                    this.$store.state.chatForm = item.receiveUser
                })
            }else{
                this.$confirm(this.$i18n.t('login.shenVip'), this.$i18n.t('giftComponents.reminder'), {
                    confirmButtonText: this.$i18n.t('userLeft.success'),
                    cancelButtonText: this.$i18n.t('giftComponents.cancel'),
                    type: 'warning'
                }).then(() => {
                    this.$store.state.sayShow = false
                    this.$nextTick(()=>{
                        this.$store.state.upgradeShow = true
                    })
                })
            }
        },
        // 数据发送
        bindEnter (item) {
            if(this.sendForm.level != 0 || item.receiveUser.userId == '2c988fde790e55f801791232ef9b0117'){
                item.noReadCount = 0
                if (!this.inputMsg){
                    return false
                } else {
                    if(this.inputMsg == '163'){
                        this.goSay(" ",item)
                    }else{
                        this.goSay(this.inputMsg,item)
                    }
                }
            }else{
                this.$confirm(this.$i18n.t('login.shenVip'), this.$i18n.t('giftComponents.reminder'), {
                    confirmButtonText: this.$i18n.t('userLeft.success'),
                    cancelButtonText: this.$i18n.t('giftComponents.cancel'),
                    type: 'warning'
                }).then(() => {
                    this.$store.state.sayShow = false
                    this.$nextTick(()=>{
                        this.$store.state.upgradeShow = true
                    })
                })
            }
        },
        goSay(content,item){
            if(this.$store.state.socketYes){
                var json = {
                    receiveId: item.receiveUser.userId,
                    content: content
                }
                this.$store.commit('setSocke',JSON.stringify(json))
                this.$isPulic.crtime((e,c,b)=>{
                    var chatJson = {
                        date: c,
                        text: {text: content},
                        mine: true,
                        name: this.sendForm.nickName,
                        img: this.$isApi.http + this.sendForm.header
                    }
                    item.chatRecord.push(chatJson)
                })
            }else{
                this.$message({
                    message: this.$i18n.t('login.diaoxian'),
                    type: 'warning'
                })
            }
        }
    }
}
</script>

<style scoped>
     /*/deep/ .web__msg textarea::-webkit-input-placeholder { color: #ffffff;}*/
     /*/deep/ .web__msg-submit span{color: #409EFF;}*/
.zai-xian{
    position: absolute;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: #00d800;
    border: 2px solid #fff;
    left: 34px;
    top: 34px;
}
.say-box{
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    z-index: 99;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center
}
.say-box-center{
    width: 60vw;
    height: 80vh;
    background: rgba(255, 255, 255, 1);
    box-shadow: 4px 4px 10px rgba(0, 0, 0, .2);
    position: relative;
}
.say-box-close{
    width: 30px;
    height: 30px;
    background: #ccc;
    border-radius: 50%;
    text-align: center;
    line-height: 30px;
    position: absolute;
    right: -15px;
    top: -15px;
    cursor: pointer;
    color: #fff;
}
.say-box-close:hover{
    color: #fff;
    background: #f00
}
.say-box-s{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
}
.say-box-s>div:nth-child(1){
    width: 20%;
    height: 80vh;
    background: #fff;
    overflow-y: scroll
}
.say-box-s>div:nth-child(1)::-webkit-scrollbar {
    width: 2px;
}
.say-box-s>div:nth-child(1)::-webkit-scrollbar-thumb {
    border-radius: 2px;
    background: rgba(0,0,0,.2);
}
.say-box-s>div:nth-child(1)::-webkit-scrollbar-track {
    border-radius: 2px;
    background: rgba(0,0,0,0);
}
.say-box-s>div:nth-child(2){
    width: 80%;
    height: 100%;
    background: rgba(0, 0, 0, .01);
    border-left: 1px solid #eee;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
}
.say-box-user{
    height: 54px;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0 10px;
    background: #eee;
    cursor: pointer;
    border-bottom: 1px solid #ccc;
    position: relative;
}
.say-box-user:hover{
    background: rgba(0, 0, 0, .02)
}.say-box-user:hover>div:nth-child(3){
    display: block
}
.say-box-user>img{
    display: block;
    width: 36px;
    height: 36px;
    border-radius: 50%;
    overflow: hidden;
    object-fit: cover;
    margin-right: 10px;
}
.say-nosay{
    position: absolute;
    left: 36px;
    top: 4px;
    width: max-content;
    height: max-content;
    padding: 0 5px;
    border-radius: 10px;
    color: #fff;
    font-size: 12px;
    background: #f00
}
.say-box-user>div:nth-child(2){
    font-size: 14px;
    flex: 1;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
}
.say-box-user>div:nth-child(3){
    width: max-content;
    height: max-content;
    font-size: 12px;
    color: rgba(255, 0, 0, .4);
    border-radius: 12px;
    margin-left: 10px;
    display: none
}
.say-box-user>div:nth-child(3):hover{
    color: #f00;
    font-weight: bold
}
.activat{
    background: rgba(255, 0, 0, .3) !important;
}

.say-top{
    height: 60px;
    background: #FD463E;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0 20px;
}
.say-top>div:nth-child(1){
    width: 40px;
    height: 40px;
    margin-right: 10px;
    position: relative;
}
.say-top>div:nth-child(1)>img{
    width: 40px;
    height: 40px;
    overflow: hidden;
    border-radius: 50%;
    object-fit: cover;
}
.say-top>div:nth-child(1)>div{
    position: absolute;
    right: -6px;
    bottom: -2px;
    color: #FFE00F;
    font-size: 18px;
}
.say-top>div:nth-child(2){
    height: max-content;
    flex: 1;
}
.say-top>div:nth-child(2)>div:nth-child(1){
    font-size: 16px;
    color: #fff;
    font-weight: bold;
    margin-bottom: 2px;
}
.say-top>div:nth-child(2)>div:nth-child(2){
    font-size: 12px;
    color: rgba(255, 214, 211, 1);
}
.say-top>div:nth-child(3){
    color: rgba(255, 255, 255, .6);
    cursor: pointer;
}
.say-top>div:nth-child(3):hover{
    color: #fff;
}
.say-gift{
    height: 40px;
    border-bottom: 1px solid #ccc;
    background: rgba(0, 0, 0, .04);
    display: flex;
    justify-content: space-between;
}
.say-gift-left{
    display: flex;
    height: 40px;
}
.say-gift-left>div{
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 40px;
    cursor: pointer;
    padding: 0 20px;
}
.say-gift-left>div:hover{
    background: rgba(0, 0, 0, .1)
}
.say-gift-left>div>i{
    width: 22px;
    height: 22px;
    display: block;
    text-align: center;
    line-height: 22px;
    font-size: 14px;
    color: #fff;
    border-radius: 50%;
}
.say-gift-left>div>span{
    font-size: 14px;
    margin-left: 6px;
}
.say-gift-right{
    display: flex;
    height: 40px;
    flex-direction: row;
    align-items: center;
    margin-right: 20px;
}
.say-gift-right>div{
    display: flex;
    height: 40px;
    flex-direction: row;
    align-items: center;
    margin-left: 20px;
    cursor: pointer;
}
.say-gift-right>div>i{
    font-size: 26px;
    color: #f00;
    display: block
}
.say-gift-right>div>span{
    font-size: 14px
}
.say-chat{
    flex: 1;
    background: #f00
}
</style>
